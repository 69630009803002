<template>
	<div>
		<main-nav :activeIndex="activeIndex"></main-nav>
		<div class="solution-header-container">
			<div class="solution-header-text">
				<h1 data-caption-delay="0" class="wow fadeInLeft">车载控制器开发</h1>
				<p data-caption-delay="100" class="wow fadeInRight">为客户提供控制器开发方案咨询及各阶段的开发服务</p>
				<div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
					<div>立即咨询</div>
				</div>
			</div>
		</div>
		<section class="solution-intros electric-solution-intro wow fadeInLeft">
			<div class="container">
				<div class="normal-title">解决方案介绍</div>
				<div class="all-intro">
					<el-row :gutter="20">
						<el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
							<div class="intro-text">
								<p>东信创智作为NXP及Vector的合作伙伴，依托多年量产开发、测试经验，为客户提供车载控制器开发解决方案。</p>
							</div>
							<div class="intro-text">
								<p>主要经验涉及：智能中央网关、车身域控制器、整车域控制器、底盘域及相关控制器、区域控制器。</p>
							</div>
						</el-col>
						<el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
							<div class="intro-picture">
								<img src="../../../assets/images/solutions/vehicle-01.png" alt="" style="width:90%">
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="left-bg">
				<img src="../../../assets/images/index-about-bg-01.png" alt="">
			</div>
			<div class="right-bg">
				<img src="../../../assets/images/index-about-bg-02.png" alt="">
			</div>
		</section>
		<section class="vehicle-solution-sections solution-functions preevision-solution-function wow fadeInRight">
			<div class="container">
				<div class="normal-title">功能特点</div>
				<el-row>
					<el-col>
						<div class="function-intro">
							<p>东信创智可为客户提供A样、B样、C样、D样及SOP等多个阶段的开发咨询及开发工程服务涉及内容主要包括：
							</p>
						</div>
					</el-col>
				</el-row>

				<div class="all-function" @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
					@mouseleave="$refs.mySwiper.swiper.autoplay.start()">
					<swiper :options="swiperOption" ref="mySwiper">
						<swiper-slide v-for="(item, index) in functionList" :key="index">
							<div class="card-item">
								<el-card class="solution-function-card" shadow="hover">
									<div class="card-icon">
										<img :src=" item.icon " alt=""
											:style="'width: ' + item.width +';padding-top:' + item.padding">
									</div>
									<div class="card-title">
										<h2>{{ item.title }}</h2>
									</div>
									<div class="card-text">
										<p>{{ item.content }}</p>
									</div>
								</el-card>
							</div>
						</swiper-slide>
						<!-- 根据情况引入控件 -->
						<!--            <div class="swiper-pagination" slot="pagination"></div>-->
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</swiper>
				</div>
			</div>
		</section>

		<bzInfo style="margin-top: 0" />
		<main-footer></main-footer>
	</div>
</template>

<script>
	import mainNav from '@/components/MainNav'
	import mainFooter from '@/components/MainFooter'
	import bzInfo from '@/components/BzInfo'
	import functionPic1 from '@/assets/images/solution-electric-function-icon-1.svg'
	import functionPic2 from '@/assets/images/solution-electric-function-icon-2.svg'
	import functionPic3 from '@/assets/images/solution-electric-function-icon-3.svg'
	// import functionPic4 from '@/assets/images/solution-electric-function-icon-4.svg'
	export default {
		name: "Architecture",
		components: {
			mainNav,
			mainFooter,
			bzInfo
		},
		data() {
			return {
				activeIndex: '2-1',
				functionList: [{
						icon: functionPic1,
						width: "45px",
						padding: "5px",
						title: "硬件设计",
						content: '控制器功能需求分析/硬件、结构需求分析/硬件选型原理图设计/Layout设计/DV实验软件'
					},
					{
						icon: functionPic2,
						width: "45px",
						padding: "5px",
						title: "控制器软件开发",
						content: 'CAN、LIN、Ethernet通信开发/SOME/IP、DDS通信/CAN、DoIP诊断/网络管理、模式管理/Bootloader/OTA/CDD/应用软件'
					},
					{
						icon: functionPic3,
						width: "45px",
						padding: "5px",
						title: "软件测试",
						content: '软件静态测试/单元测试/集成测试/系统测试'
					}
				],
				swiperOption: {
					spaceBetween: 20, // 图片之间的间距
					centeredSlides: false, // 居中还是从图1开始
					slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
					breakpointsInverse: true,
					breakpoints: {
						//当宽度大于等于1100
						1100: {
							slidesPerView: 4,
							spaceBetween: 10
						}
					},
					notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
					// loop: true, // 循环吗
					initialSlide: 0, // 从第几个开始
					autoplay: {
						delay: 5000, // 等5秒下一个
						disableOnInteraction: false // 中间滑动一下，取消自动吗？
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					}, // 下按钮
					speed: 800, // 滑动时候动画的速度
					paginationClickable: true, // 下面按钮让点吗
					navigation: {
						prevEl: '.swiper-button-prev', // 左侧按钮
						nextEl: '.swiper-button-next' // 右侧按钮
					},
					// effect: 'fade', // 渐入效果
					watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
					watchSlidesVisibility: true // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
					// autoHeight: true  // 图片高度自适应
				},
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
			}
		},
		mounted() {
			new this.$wow.WOW().init();
			document.title = "车载控制器开发 - 解决方案 - 东信创智";
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			},
		}
	}
</script>
